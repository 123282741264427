import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Mainpage} from "./pages/MainPage";

function App() {

  return (
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<Mainpage/>}/>
        </Routes>
      </BrowserRouter>
  )
}

export default App;
