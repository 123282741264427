import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

export const Mainpage = () => {
    const { search } = useLocation()

    useEffect(() => {
        if (window.navigator.language === 'th-TH') {
            window.location.href = 'https://www.lazada.co.th/'
        }
        else {
            window.location.href = `https://t.me/BudsBanger_bot${search}`
        }
    }, [window])
    return (
        <div className="App">
        </div>
    );
}

